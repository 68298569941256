import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Button, Form, Input } from "antd";
import { LoginOutlined } from '@ant-design/icons';
import { LoginMutation } from "../services/mutations";
import { isValidEmail } from "../components/helpers";
import { successToast, errorToast } from '../components/utils';
import { AuthContext } from "../state";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassowrd] = useState('');
    const [visible, setVisible] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorEmail, setErrorEmail] = useState('')
    const [errorPassword, setErrorPassword] = useState('')
    const [apiError, setApiError] = useState('');
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
    const [form] = Form.useForm();

    const API_URL = process.env.REACT_APP_API_URL;

    const navigate = useNavigate();


    useEffect(() => {
        if(email && password) {
            setVisible(false);
        } else {
            setVisible(true);
        }
    
    }, [email, password]);  

    const handleSubmit = (e) => {
        e.preventDefault();
        setApiError('');
        if (email === '' || password === '') {
            setError(true);
            errorToast('Please enter email and password');
            setErrorEmail('Please enter a valid email address');
            setErrorPassword('Please enter a valid password');
            return;
        }
        if (isValidEmail(email) === false) {
            setError(true);
            setErrorEmail('Please enter a valid email address');
            setErrorPassword('');
            return;
        };
        setLoading(true);
        axios.post(API_URL, {
            query: LoginMutation,
            variables: {
                "email": email, 
                "password":password
            }
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
         })
        .then((response) => {
            setIsAuthenticated(true);
            localStorage.setItem('accessToken', response.data.data.login.token);
            localStorage.setItem('roleID', response.data.data.login.user.role_id);
            localStorage.setItem('userID', response.data.data.login.user.id);
            localStorage.setItem('isAuthenticated', 'true');
            successToast('Welcome! '+response.data.data.login.user.first_name);
            handleLogin();
        })
        .catch((error) => {
            setLoading(false);
            setError(true);
            setApiError('Error occurred during login');
            errorToast(apiError)
            console.error('Error:', error);
        });
    };

    const handleLogin = () => {
        setLoading(false);
        return navigate('/dashboard')
    }

    const handeChangeEmail = (e) => {
        setEmail(e.target.value);
    };

    const handeChangePassword = (e) => {
        setPassowrd(e.target.value);
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <div className="gap-5">
                <h1 className="text-3xl font-bold p-10">Login</h1>
            </div>
            <div className="card bg-base-100 rounded-md w-full max-w-sm shrink-0 shadow-2xl">
                <div className="card-body p-10">
                    <Form 
                        form={form}
                        layout="vertical" 
                        autoComplete="off"
                    >
                        <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                            required: true,
                            message: 'Please input your Email!',
                            },
                        ]}
                        >
                            <Input type="text" 
                                className="grow" 
                                placeholder="Email"
                                onChange={(e) => handeChangeEmail(e)} />
                        </Form.Item>
                        {(error)?<div className="text-center text-error">{errorEmail}</div>: null}
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                required: true,
                                message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password 
                                className="grow" 
                                placeholder="****" 
                                    onChange={(e) => handeChangePassword(e)} 
 
                            />
                        </Form.Item>
                        {(error)?<div className="text-center text-error">{errorPassword}</div>: null}
                        <div className="flex items-end">
                            <div className="form-control">
                                <Form.Item style={{ padding: 0, margin: 0}}>
                                    {(loading === false)?
                                    (
                                        <Button 
                                        
                                        type="primary" 
                                        htmlType="submit" 
                                        icon={<LoginOutlined />}
                                        onClick={(e) => handleSubmit(e)}
                                    >
                                        Login
                                    </Button>):
                                    (<Button type="primary" loading iconPosition='start'>
                                        login
                                    </Button>)}
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                    <div className="flex form-control text-center mt-6">
                        <Link to="/forgot-password" className="text-sm text-[#50d71e]">Forgot Password?</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;